import React from 'react';
import {Route, Switch} from "react-router-dom";
import menuConfig from '../core/menu';
import User from "./users/User";
import Setting from "./settings/Setting";
import SettingsCategories from './settings/Categories';

export default function RouterView() {
    const menuRoutes = menuConfig.map(route => <Route path={route.link} key={route.link} component={route.component}/>);
    return (
        <Switch>
            {menuRoutes}
            <Route path="/user/:id" component={User}/>
            <Route exact strict path="/struct/:id" component={Setting}/>
            <Route exact strict path="/categories" component={SettingsCategories}/>
        </Switch>
    );
};
