import React, { Component } from 'react';
import queryString from 'query-string';
import API from '../../core/axios';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/es/TextField/TextField';
import Button from '@material-ui/core/es/Button/Button';
import Select from '@material-ui/core/es/Select/Select';
import Input from '@material-ui/core/es/Input/Input';
import MenuItem from '@material-ui/core/es/MenuItem/MenuItem';

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      id: null,
      category_id: '',
      version_id: null,
      categories: []
    };
  }

  async componentDidMount() {
    try {
      const queryParams = queryString.parse(this.props.location.search);
      const structId = this.props.match.params.id;
      if (structId !== 'add') {
        const struct = (await API.get(`struct/${structId}`)).data.data;
        const categories = (await API.get('struct-category')).data.data;
        this.setState({ ...struct, categories, version_id: queryParams.v });
      } else {
        const categories = (await API.get('struct-category')).data.data;
        this.setState({ id: 'add', categories, version_id: queryParams.v });
      }
    } catch (e) {
      toast.error('Ошибка');
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  addStruct = async event => {
    event.preventDefault();
    try {
      const struct = { ...this.state };
      await API.post('struct/', struct);
      toast.success('Структура добавлена');
      this.props.history.goBack();
    } catch (e) {
      toast.error('Ошибка');
    }
  };

  updateStruct = async event => {
    event.preventDefault();
    try {
      const struct = { ...this.state };
      await API.post(`struct/${struct.id}`, struct);
      toast.success('Структура обновлена');
      this.props.history.goBack();
    } catch (e) {
      toast.error('Ошибка');
    }
  };

  render() {
    const { id, name, categories, category_id } = this.state;
    const presetOptions = categories.map((p, index) => (
      <MenuItem key={index} value={p.id}>
        {p.name}
      </MenuItem>
    ));
    return (
      <section>
        <h1>{id === 'add' ? 'Добавить' : 'Редактировать'} структуру</h1>
        {id && (
          <form onSubmit={id === 'add' ? this.addStruct : this.updateStruct}>
            <TextField
              fullWidth={true}
              label="Имя"
              value={name}
              onChange={this.handleChange('name')}
              margin="normal"
            />
            <Select
              value={category_id}
              onChange={this.handleChange('category_id')}
              style={{ display: 'block' }}
              input={<Input name="version" />}
            >
              {presetOptions}
            </Select>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '1rem 0', float: 'right' }}
              type="submit"
            >
              Сохранить
            </Button>
          </form>
        )}
      </section>
    );
  }
}

export default Setting;
