import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import VersionsIcon from '@material-ui/icons/History';
import Users from "../views/users/Users";
import SettingsView from "../views/settings/SettingsView.jsx";
import Presets from "../views/presets/Presets";
import Categories from "../views/settings/Categories";

const config = [
    {name: 'Пользователи', link: '/users', icon: <AccountCircle/>, component: Users},
    {name: 'Настройки', link: '/structs', icon: <SettingsIcon/>, component: SettingsView},
    {name: 'Категории', link: '/struct-categories', icon: <DashboardIcon/>, component: Categories},
    {name: 'Пресеты', link: '/versions', icon: <VersionsIcon/>, component: Presets},
];

export default config;
