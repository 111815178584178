import React, {Component} from 'react';
import API from '../../core/axios';
import axios from '../../core/axios';
import {toast} from 'react-toastify';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/es/Button/Button';
import IconButton from '@material-ui/core/es/IconButton/IconButton';
import {confirmDialogue} from '../../utils';
import groupBy from 'lodash/groupBy';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

class SettingParams extends Component {
    constructor(props) {
        super(props);
        this.additionFormNameInput = React.createRef();
        this.state = {
            settings: [],
            categories: [],
            value: '',
            name: '',
            category_id: 1, //It's other category (default)
            loading: false
        };
    }

    async componentDidMount() {
        const categories = (await axios.get('struct-category')).data.data;
        this.setState({categories});
    }

    async componentWillReceiveProps(nextProps) {
        try {
            this.setState({loading: true});
            const structId = nextProps.struct.id;
            const data = (await API.get(`struct/${structId}`)).data.data;
            data.settings = data.settings.map(this.fixCategory);
            this.setState({...data, name: '', loading: false});
        } catch (e) {
            toast.error('Ошибка');
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    handleSettingsChange = (event, item, key) => {
        const {settings} = this.state;
        const index = settings.findIndex(i => i.id === item.id);
        settings[index][key] = event.target.value;
        settings[index].was_changed = true;
        this.setState({settings});
    };

    fixCategory(item) {
        return {
            ...item,
            category: item.category ? item.category.name : 'Other',
            category_id: item.category ? item.category.id : 1
        };
    }

    addSetting = async event => {
        event.preventDefault();
        try {
            const setting = {
                struct_id: this.state.id,
                name: this.state.name,
                value: this.state.value,
                category_id: this.state.category_id
            };
            const newSetting = (await API.post('setting/', setting)).data.data;
            const {settings} = this.state;
            settings.push({...this.fixCategory(newSetting)});
            this.setState({settings, name: '', value: ''});
            this.additionFormNameInput.current.focus();
            toast.success('Настройка добавлена');
        } catch (e) {
            toast.error('Ошибка');
        }
    };

    removeSetting = async setting => {
        const confirmed = await confirmDialogue(
            `Вы уверенны, что хотите удалить настройку?`
        );
        if (confirmed) {
            try {
                API.delete(`setting/${setting.id}`);
                const settings = this.state.settings.filter(
                    item => item.id !== setting.id
                );
                toast.success('Настройка удалена');
                this.setState({settings});
            } catch (e) {
                toast.error('Ошибка');
            }
        }
    };

    saveSetting = async setting => {
        try {
            this.validateSetting(setting);
            const changedSetting = (await API.post(`setting/${setting.id}`, setting))
                .data.data;
            const {settings} = this.state;
            const index = settings.findIndex(
                element => element.id === changedSetting.id
            );
            settings[index] = {
                ...changedSetting,
                category_id: changedSetting.category.id,
                category: changedSetting.category.name
            };
            this.setState({settings});
            toast.success('Настройка сохранена');
        } catch (e) {
            setting.error = true;
            toast.error(e.message ? e.message : e);
        }
    };

    handleCategoryChange = async (setting, category) => {
        const category_id = this.state.categories.find(c => c.name === category).id;
        this.saveSetting({...setting, category_id});
    };

    validateSetting(setting) {
        const {name, value} = setting;
        if (name.length && value.length) {
            return true;
        } else {
            throw new Error('Имя и значение - обязательные поля');
        }
    }

    render() {
        const {
            settings,
            name,
            value,
            category_id,
            categories,
            loading
        } = this.state;
        const {struct} = this.props;
        return (
            <section className={`view ${loading && 'loading'}`}>
                <div className="section-header">
                    <h1>Настройки {struct.name}</h1>
                </div>
                <div className="table-container">
                    <SettingOptions
                        settings={settings}
                        categories={categories}
                        change={this.handleSettingsChange}
                        changeCategory={this.handleCategoryChange}
                        save={this.saveSetting}
                        remove={this.removeSetting}
                    />
                    <form className="addition-form" onSubmit={this.addSetting}>
                        <input
                            type="text"
                            value={name}
                            onChange={this.handleChange('name')}
                            ref={this.additionFormNameInput}
                            required
                            placeholder="Имя"
                        />
                        <select
                            value={category_id}
                            onChange={this.handleChange('category_id')}
                        >
                            {categories.map(i => (
                                <option value={i.id} key={i.id}>
                                    {i.name}
                                </option>
                            ))}
                        </select>
                        <input
                            type="text"
                            value={value}
                            onChange={this.handleChange('value')}
                            required
                            placeholder="Значение"
                        />
                        <Button variant="contained" color="primary" type="submit">
                            <AddIcon/>
                        </Button>
                    </form>
                </div>
            </section>
        );
    }
}

export default SettingParams;

const reorder = (list, source, startIndex, target, endIndex) => {
    const result = {...list};
    const [removed] = result[source].splice(startIndex, 1);
    result[target].splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#3f51b5' : 'transparent',
    color: isDragging ? '#fafafa' : 'inherit',
    ...draggableStyle,
});

class SettingOptions extends Component {
    state = {
        list: {}
    };

    componentWillReceiveProps(nextProps) {
        const list = groupBy(nextProps.settings, 'category');
        for (let category in list) {
            list[category].sort((a, b) => a.order_number - b.order_number);
        }
        const listCategories = Object.keys(list);
        nextProps.categories.forEach(c => {
            if (!listCategories.includes(c.name)) {
                list[c.name] = []
            }
        });
        this.setState({list});
    }

    saveOrder = async list => {
        try {
            let data = [];
            for (let category in list) {
                const order = list[category].map(i => i.id);
                data = [...data, ...order];
            }
            await axios.post('/setting/order', {ids: data});
            toast.success('Порядок сохранен');
        } catch (e) {
            console.error(e);
            toast.error('Ошибка!');
        }
    };

    onDragEnd = async result => {
        if (!result.destination) {
            return;
        }

        const targetItem = this.state.list[result.source.droppableId][
            result.source.index
            ];

        const list = reorder(
            this.state.list,
            result.source.droppableId,
            result.source.index,
            result.destination.droppableId,
            result.destination.index
        );

        if (result.source.droppableId !== result.destination.droppableId) {
            await this.props.changeCategory(
                targetItem,
                result.destination.droppableId
            );
        }
        await this.saveOrder(list);
        this.setState({list});
    };

    render() {
        let categories = [];
        const {change, save, remove} = this.props;
        for (let category in this.state.list) {
            categories.push(
                <div className="struct-setting" key={category}>
                    <h1>{category}</h1>
                    <table>
                        <Droppable droppableId={category}>
                            {(provided, snapshot) => (
                                <tbody ref={provided.innerRef}>
                                {this.state.list[category].map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <td>{item.id}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={item.name}
                                                        onChange={e => change(e, item, 'name')}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={item.value}
                                                        onChange={e => change(e, item, 'value')}
                                                    />
                                                </td>
                                                <td>
                                                    {item.was_changed && (
                                                        <IconButton
                                                            color="primary"
                                                            aria-label="Сохранить"
                                                            onClick={() => save(item)}
                                                        >
                                                            <SaveIcon fontSize="small"/>
                                                        </IconButton>
                                                    )}
                                                    <IconButton
                                                        color="secondary"
                                                        aria-label="Удалить"
                                                        onClick={() => remove(item)}
                                                    >
                                                        <DeleteIcon fontSize="small"/>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                </tbody>
                            )}
                        </Droppable>
                    </table>
                </div>
            );
        }
        return (
            <div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    {categories}
                </DragDropContext>
            </div>
        );
    }
}
