import React, {Component} from 'react';
import API from '../../core/axios';
import {toast} from 'react-toastify';
import Link from "react-router-dom/es/Link";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import {confirmDialogue} from "../../utils";
import Button from "@material-ui/core/es/Button/Button";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: []
        }
    }

    async componentDidMount() {
        try {
            const users = (await API.get('user')).data.data;
            this.setState({users});
        } catch (e) {
            toast.error('Ошибка');
        }
    }

    async removeUser(user) {
        const confirmed = await confirmDialogue(`Вы уверенны, что хотите удалить пользователя ${user.name}?`);
        if (confirmed) {
            try {
                API.delete(`user/${user.id}`);
                const users = this.state.users.filter(item => item.id !== user.id);
                toast.success('Пользователь удален');
                this.setState({users});
            } catch (e) {
                toast.error('Ошибка');
            }
        }
    }

    render() {
        const {users} = this.state;
        return (
            <section className="view">
                <div className="section-header">
                    <h1>Пользователи</h1>
                    <Link to="/user/add" style={{textDecoration: 'none'}}>
                        <Button variant="outlined" color="primary">Добавить</Button>
                    </Link>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Имя</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell className="text-center">Редактировать</TableCell>
                        </TableRow>
                    </TableHead>
                    <UsersList users={users} onRemove={(user) => this.removeUser(user)} />
                </Table>
            </section>
        )
    }
}

export default Users;

function UsersList({users, onRemove}) {
    const usersRows = users.map(user => (
        <TableRow hover key={user.id.toString()}>
            <TableCell>{user.id}</TableCell>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell className="text-center">
                <Link to={`user/${user.id}`} style={{color: 'inherit', textDecoration: 'none'}}>
                    <IconButton aria-label="Редактировать">
                        <EditIcon fontSize="small"/>
                    </IconButton>
                </Link>
                <IconButton color="secondary" aria-label="Удалить"
                            onClick={() => onRemove(user)}>
                    <DeleteIcon fontSize="small"/>
                </IconButton>
            </TableCell>
        </TableRow>
    ));
    return (
        <TableBody>
            {usersRows}
        </TableBody>
    )
}
