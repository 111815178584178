import React, {Component} from 'react';
import API from '../../core/axios';
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";
import Input from "@material-ui/core/es/Input/Input";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import {toast} from 'react-toastify';
import Settings from "./Settings";
import Divider from "@material-ui/core/es/Divider/Divider";

const PresetSelect = ({presets, selected, onChange}) => {
    const presetOptions = presets.map((p, index) => <MenuItem key={index} value={p.id}>{p.name}</MenuItem>);
    return (
        <FormControl style={{width: '100%'}}>
            <InputLabel htmlFor="age-helper">Пресет</InputLabel>
            <Select value={selected} onChange={onChange} input={<Input name="version"/>}>
                {presetOptions}
            </Select>
        </FormControl>
    )
};

class SettingsView extends Component {

    state = {
        selected: '',
        presets: [],
        settings: null,
    };

    async componentDidMount() {
        const presets = await this.getPresets();
        this.setState({presets})
    }

    getPresets = async () => {
        try {
            const res = await API.get('version');
            return res.data.data;
        } catch (e) {
            toast.error('Не удалось получить список версий');
            return [];
        }
    };
    handleChange = (e) => {
        this.setState({selected: e.target.value})
    };

    render() {
        const {presets, selected} = this.state;
        return (
            <section className="view">
                <div className="section-header"><h1>Настройки</h1></div>   
                <PresetSelect presets={presets} selected={selected} onChange={this.handleChange}/>
                <Divider/>
                <Settings version={selected}/>
            </section>
        );
    }
}

export default SettingsView;
