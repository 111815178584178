import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import RouterView from "./views/RouterView";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppAside from "./components/Aside";

const styles = () => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: '0.5rem',
        height: '100vh',
        overflow: 'auto'
    }
});

class Dashboard extends Component {
    render() {
        const {classes} = this.props;
        return (<div className={classes.root}>
            <AppAside/>
            <main className={classes.content}>
                <RouterView/>
            </main>
            <ToastContainer position="bottom-right" hideProgressBar={true} autoClose={2000}/>
        </div>);
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
