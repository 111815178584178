import React from 'react';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/es/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/es/ListItemIcon/ListItemIcon";
import NavLink from "react-router-dom/es/NavLink";
import ListItemText from "@material-ui/core/es/ListItemText/ListItemText";
import List from "@material-ui/core/es/List/List";

MainMenu.propTypes = {
    config: PropTypes.array.isRequired
};

export default function MainMenu({config}) {
    const menuItems = config.map(item => {
        return (
            <NavLink to={item.link} key={item.link} activeClassName='active-router-link' style={{textDecoration: 'none'}}>
                <ListItem button>
                    <ListItemIcon>
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name}/>
                </ListItem>
            </NavLink>
        )
    });
    return <List style={{padding: '0'}}>{menuItems}</List>;
};
