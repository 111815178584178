import React, {Component} from 'react';
import API from '../../core/axios';
import {toast} from "react-toastify";
import TextField from "@material-ui/core/es/TextField/TextField";
import Button from "@material-ui/core/es/Button/Button";

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            id: null
        }
    }

    async componentDidMount() {
        try {
            const userId = this.props.match.params.id;
            if (userId !== 'add') {
                const user = (await API.get(`user/${userId}`)).data.data;
                this.setState({...user})
            } else {
                this.setState({id: 'add'})
            }
        } catch (e) {
            toast.error('Ошибка');
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    addUser = async (event) => {
        event.preventDefault();
        try {
            const user = {...this.state};
            await API.post('user/', user);
            toast.success('Пользователь добавлен');
            this.props.history.goBack();
        } catch (e) {
            toast.error('Ошибка');
        }
    };

    updateUser = async (event) => {
        event.preventDefault();
        try {
            const user = {...this.state};
            await API.post(`user/${user.id}`, user);
            toast.success('Пользователь обновлен');
            this.props.history.goBack();
        } catch (e) {
            toast.error('Ошибка');
        }
    };

    render() {
        const {id, name, email} = this.state;
        return (
            <section>
                <h1>{id === 'add' ? 'Добавить' : 'Редактировать'} пользователя</h1>
                {
                    id &&
                    (<form onSubmit={id === 'add' ? this.addUser : this.updateUser}>
                        <TextField
                            fullWidth={true}
                            label="Имя"
                            value={name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                        />
                        <TextField
                            fullWidth={true}
                            label="Email"
                            value={email}
                            onChange={this.handleChange('email')}
                            margin="normal"
                        />
                        <Button variant="contained"
                                color="primary"
                                style={{margin: '1rem 0', float: 'right'}}
                                type="submit">
                            Сохранить
                        </Button>
                    </form>)
                }
            </section>
        )
    }
}

export default User;
