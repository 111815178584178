import React, {Component} from 'react';
import API from '../../core/axios';
import {toast} from "react-toastify";
import './presets.scss';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableFooter from "@material-ui/core/es/TableFooter/TableFooter";
import PresetAdditionForm from "./AdditionForm";

class Presets extends Component {

    state = {
        presets: [],
        loading: false
    };

    async componentDidMount() {
        this.setState({loading: true});
        const presets = await this.getPresets();
        this.setState({presets, loading: false})
    }

    getPresets = async () => {
        try {
            const res = (await API.get('version')).data.data;
            return res;
        } catch (e) {
            toast.error('Не удалось получить список версий');
            return [];
        }
    };

    handleActivate = async version => {
        if (window.confirm('Вы уверены, что хотите переключить активный пресет настроек?')) {
            try {
                await API.post(`version/${version.id}`, {...version, is_active: true});
                const presets = this.state.presets.map(item => {
                    item.is_active = item.id === version.id;
                    return item;
                });
                this.setState({activeVersion: version.id, presets});
                toast.success('Успех')
            } catch (e) {
                toast.error('Ошибка')
            }
        }
    };

    handleRemove = versionId => {
        if (window.confirm('Вы уверены, что хотите удалить пресет?')) {
            try {
                API.delete(`version/${versionId}`);
                const presets = this.state.presets.filter(v => v.id !== versionId);
                this.setState({presets});
                toast.success('Удалено');
            } catch (e) {
                toast.error('Ошибка');
            }
        }
    };

    handleSubmit = newPreset => {
        let presets = [...this.state.presets];
        presets.push(newPreset);
        this.setState({presets});
    };

    render() {
        const {presets, loading} = this.state;
        return (
            <section className={`view ${loading && 'loading'}`}>
                <div className="section-header">
                    <h1>Менеджер пресетов</h1>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Активная версия</TableCell>
                            <TableCell style={{width: 100}} className="text-center">Удалить</TableCell>
                        </TableRow>
                    </TableHead>
                    <PresetsList presets={presets} onActivate={this.handleActivate}
                                 onRemove={this.handleRemove}/>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <PresetAdditionForm presets={presets} onSubmit={this.handleSubmit}/>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </section>
        );
    }
}

export default Presets;

const PresetsList = ({presets, onActivate, onRemove}) => {
    const versionsRows = presets.map(preset => (
        <TableRow key={preset.id}>
            <TableCell>
                <label className="container">
                    {preset.name}
                    <input type="radio" checked={preset.is_active} name="radio" value={preset.id}
                           onChange={() => onActivate(preset)}/>
                    <span className="checkmark"></span>
                </label>
            </TableCell>
            <TableCell className="text-center">
                <IconButton disabled={preset.is_active} color="secondary" aria-label="Удалить"
                            onClick={() => onRemove(preset.id)}>
                    <DeleteIcon fontSize="small"/>
                </IconButton>
            </TableCell>
        </TableRow>
    ));
    return (
        <TableBody>
            {versionsRows}
        </TableBody>
    )
};
