import React, { Component } from 'react';
import axios from '../../core/axios';
import { toast } from 'react-toastify';
import Input from '@material-ui/core/es/Input/Input';
import Button from '@material-ui/core/es/Button/Button';
import Table from '@material-ui/core/es/Table/Table';
import TableHead from '@material-ui/core/es/TableHead/TableHead';
import TableRow from '@material-ui/core/es/TableRow/TableRow';
import TableCell from '@material-ui/core/es/TableCell/TableCell';
import TableBody from '@material-ui/core/es/TableBody/TableBody';
import IconButton from '@material-ui/core/es/IconButton/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmDialogue } from '../../utils';

export default class SettingsCategories extends Component {
  state = {
    loading: false,
    categories: []
  };

  async componentDidMount() {
    try {
      this.setState({ loading: false });
      const categories = await this.getCategories();
      this.setState({ categories, loading: false });
    } catch (e) {
      toast.error('Ошибка');
    }
  }

  async getCategories() {
    return (await axios.get('struct-category')).data.data;
  }

  handleSubmit = async data => {
    try {
      const newCategory = (await axios.post('struct-category/', data)).data
        .data;
      let categories = [...this.state.categories];
      categories.push(newCategory);
      this.setState({ categories });
      toast.success('Добавлено');
    } catch (e) {
      toast.error('Ошибка');
    }
  };

  handleRemove = async category => {
    const confirmed = await confirmDialogue(
      'Вы уверены, что хотите удалить категорию?'
    );
    if (confirmed) {
      try {
        await axios.delete(`struct-category/${category.id}`);
        this.setState(state => ({
          categories: state.categories.filter(c => c.id !== category.id)
        }));
        toast.success('Удалено');
      } catch (e) {
        toast.error('Ошибка');
      }
    }
  };

  render() {
    const { loading, categories } = this.state;
    const categoriesList = categories.map(c => (
      <Category category={c} handleRemove={this.handleRemove} key={c.id} />
    ));
    return (
      <section className={`view ${loading && 'loading'}`}>
        <div className="section-header">
          <h1>Категории настроек</h1>
        </div>

        <div className="table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Имя</TableCell>
                <TableCell className="text-center">Редактировать</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoriesList}
              <TableRow>
                <TableCell colSpan={3}>
                  <CategoryAdditionForm handleSubmit={this.handleSubmit} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </section>
    );
  }
}

class CategoryAdditionForm extends Component {
  state = {
    name: ''
  };

  inputRef = React.createRef();

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  reset() {
    this.inputRef.current.focus();
    this.setState({ name: '' });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSubmit(this.state);
    this.reset();
  };

  render() {
    return (
      <form className="categories__addition-form" onSubmit={this.handleSubmit}>
        <Input
          value={this.state.name}
          inputRef={this.inputRef}
          onChange={this.handleChange('name')}
          type="text"
          placeholder="Название"
          required
        />
        <Button variant="contained" color="primary" type="submit">
          Добавить
        </Button>
      </form>
    );
  }
}

class Category extends Component {
  state = {
    category: { name: '', error: false, ...this.props.category }
  };

  handleChange = e => {
    const { category } = this.state;
    category.name = e.target.value;
    this.setState({ category, was_changed: true });
  };

  handleRemove = this.props.handleRemove;

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleUpdate();
    }
  };

  handleUpdate = async () => {
    const { category } = this.state;
    if (category.name.length) {
      try {
        await axios.post(`struct-category/${category.id}`, category);
        this.setState({ was_changed: false, error: false });
        toast.success('Обновлено');
      } catch (e) {
        toast.error('Ошибка');
      }
    } else {
      this.setState({ error: true });
      toast.error('Имя не может быть пустым');
    }
  };

  render() {
    const { category, was_changed, error } = this.state;
    return (
      <TableRow>
        <TableCell>{category.id}</TableCell>
        <TableCell>
          <Input
            error={error}
            value={category.name}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
          />
        </TableCell>
        <TableCell className="text-center">
          {was_changed && (
            <IconButton
              color="primary"
              aria-label="Сохранить"
              onClick={this.handleUpdate}
            >
              <SaveIcon fontSize="small" />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            aria-label="Удалить"
            onClick={() => this.handleRemove(category)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}
