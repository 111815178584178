import React, {Component} from 'react';
import classNames from 'classnames';
import menuConfig from "../core/menu";
import Drawer from "@material-ui/core/es/Drawer/Drawer";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from "@material-ui/core/es/Divider/Divider";
import MainMenu from "./Menu";
import {withStyles} from "@material-ui/core/styles/index";

const styles = theme => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 220,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    }
});

class AppAside extends Component {
    state = {
        open: false
    };

    handleDrawerToggle = () => {
        this.setState(state => ({open: !state.open}));
    };

    render() {
        const {classes} = this.props;
        return (
            <Drawer variant="permanent" open={this.state.open}
                    classes={{paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose)}}>
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={this.handleDrawerToggle}>
                        {this.state.open ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                <MainMenu config={menuConfig}/>
            </Drawer>
        );
    }
}

export default withStyles(styles)(AppAside);
