import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import NativeSelect from "@material-ui/core/es/NativeSelect/NativeSelect";
import Input from "@material-ui/core/es/Input/Input";
import Button from "@material-ui/core/es/Button/Button";
import API from "../../core/axios";
import {toast} from "react-toastify";

class PresetAdditionForm extends PureComponent {

    state = {
        version_id: '',
        name: ''
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value})
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const newVersion = (await API.post('version/', this.state)).data.data;
            this.props.onSubmit(newVersion);
            this.resetForm();
            toast.success('Версия добавлена');
        } catch (e) {
            toast.error('Ошибка')
        }
    };

    resetForm = () => {
        this.setState({version_id: '', name: ''})
    };

    render() {
        const {version_id, name} = this.state;
        const {presets} = this.props;
        const presetOptions = presets.map(p => (<option key={p.id} value={p.id}>{p.name}</option>));
        return (
            <form className="preset__addition-form" onSubmit={this.handleSubmit}>
                <NativeSelect value={version_id} onChange={this.handleChange('version_id')} required>
                    <option value="">Источник</option>
                    {presetOptions}
                </NativeSelect>
                <Input value={name} onChange={this.handleChange('name')} type="text" placeholder="Название"
                       required/>
                <Button color="primary" variant="contained" type="submit">Добавить</Button>
            </form>
        );
    }
}

PresetAdditionForm.propTypes = {
    presets: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default PresetAdditionForm;
