import React, {Component} from 'react';
import Resizable from 're-resizable';
import API from '../../core/axios';
import {toast} from 'react-toastify';
import sortBy from 'lodash/sortBy';
import Link from 'react-router-dom/es/Link';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/es/IconButton/IconButton';
import Table from '@material-ui/core/es/Table/Table';
import TableHead from '@material-ui/core/es/TableHead/TableHead';
import TableRow from '@material-ui/core/es/TableRow/TableRow';
import TableCell from '@material-ui/core/es/TableCell/TableCell';
import TableBody from '@material-ui/core/es/TableBody/TableBody';
import {confirmDialogue} from '../../utils';
import Button from '@material-ui/core/es/Button/Button';
import TextField from '@material-ui/core/es/TextField/TextField';
import SettingParams from './SettingParams';
import './settings.scss';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: [],
            sorted_by: null,
            searchValue: '',
            selectedSettings: null,
            loading: false
        };
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.version) {
            try {
                this.setState({loading: true});
                const settings = (await API.get(`struct/version/${nextProps.version}`))
                    .data.data;
                this.setState({settings, loading: false});
            } catch (e) {
                toast.error('Ошибка');
            }
        }
    }

    searchStruct = event => {
        this.setState({searchValue: event.target.value});
    };

    openSettings = setting => {
        this.setState({selectedSettings: setting});
    };

    sortBy = key => {
        if (key !== this.state.sorted_by) {
            const sortedSettings = sortBy(this.state.settings, key);
            this.setState({sorted_by: key, settings: sortedSettings});
        } else {
            this.setState(state => ({struts: state.settings.reverse()}));
        }
    };

    async removeSetting(setting) {
        const confirmed = await confirmDialogue(
            'Вы уверенны, что хотите удалить структуру?'
        );
        if (confirmed) {
            try {
                API.delete(`struct/${setting.id}`);
                const settings = this.state.settings.filter(s => s.id !== setting.id);
                toast.success('Структура удалена');
                this.setState({settings});
            } catch (e) {
                toast.error('Ошибка');
            }
        }
    }

    render() {
        const {settings, searchValue, selectedSettings, loading} = this.state;
        const filteredRows = settings
            .filter(item => {
                return (
                    item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.id.toString().includes(searchValue)
                );
            })
            .sort((a, b) => a.category.localeCompare(b.category));
        const settingsRows = filteredRows.map(struct => (
            <StructRow
                selectedSettings={selectedSettings && selectedSettings.id === struct.id}
                key={struct.id}
                version={this.props.version}
                struct={struct}
                onClick={() => this.openSettings(struct)}
                removeSetting={() => this.removeSetting(struct)}
            />
        ));
        return (
            <div>
                {this.props.version && (
                    <section className={`structs-list view ${loading && 'loading'}`}>
                        <div className="main">
                            <div className="section-header">
                                <h1>Настройки</h1>
                                <Link
                                    to={`/struct/add?v=${this.props.version}`}
                                    style={{textDecoration: 'none'}}
                                >
                                    <Button variant="outlined" color="primary">
                                        Добавить
                                    </Button>
                                </Link>
                            </div>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="Поиск"
                                value={searchValue}
                                onChange={this.searchStruct}
                                margin="normal"
                            />
                            <div className="table-container">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell onClick={() => this.sortBy('id')}>
                                                ID
                                            </TableCell>
                                            <TableCell onClick={() => this.sortBy('name')}>
                                                Имя
                                            </TableCell>
                                            <TableCell className="text-center">
                                                Редактировать
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{settingsRows}</TableBody>
                                </Table>
                            </div>
                        </div>
                        {selectedSettings && (
                            <Resizable
                                className="aside"
                                minWidth={600}
                                defaultSize={{
                                    width: 600,
                                    height: 'calc(100vh - 130px)'
                                }}
                                enable={{
                                    top: false,
                                    right: false,
                                    bottom: false,
                                    left: true,
                                    topRight: false,
                                    bottomRight: false,
                                    bottomLeft: false,
                                    topLeft: false
                                }}
                            >
                                <SettingParams struct={selectedSettings}/>
                            </Resizable>
                        )}
                    </section>
                )}
            </div>
        );
    }
}

export default Settings;

function StructRow({
                       struct,
                       version,
                       selectedSettings,
                       removeSetting,
                       onClick
                   }) {
    return (
        <TableRow
            hover
            key={struct.id}
            className={`${selectedSettings && 'selected'}`}
        >
            <TableCell onClick={onClick}>{struct.id}</TableCell>
            <TableCell onClick={onClick}>{struct.name}</TableCell>
            <TableCell className="text-center">
                <Link
                    to={`struct/${struct.id}?v=${version}`}
                    style={{color: 'inherit', textDecoration: 'none'}}
                >
                    <IconButton aria-label="Редактировать">
                        <EditIcon fontSize="small"/>
                    </IconButton>
                </Link>
                <IconButton
                    color="secondary"
                    aria-label="Удалить"
                    onClick={removeSetting}
                >
                    <DeleteIcon fontSize="small"/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
}
